import { template as template_342c1ec4cbca4528995fd85021bf85ec } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_342c1ec4cbca4528995fd85021bf85ec(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
