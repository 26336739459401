import { template as template_f6082fe3e41c4ae19235a1f16242ccea } from "@ember/template-compiler";
const FKLabel = template_f6082fe3e41c4ae19235a1f16242ccea(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
