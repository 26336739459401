import { template as template_bde0f5853e3746469a34f80881316b3b } from "@ember/template-compiler";
const SidebarSectionMessage = template_bde0f5853e3746469a34f80881316b3b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
